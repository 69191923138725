import axios from 'axios';
import {getTaskUrl} from './util';
import {Task} from "@interfaces/task";

const getTaskById = async (taskId: string | number) => {
  const url = `${getTaskUrl()}/task/${taskId}/details`;
  const response = await axios.get(url);

  return response.data as Task;
};

export default getTaskById;