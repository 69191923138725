import React, {useEffect, useState} from 'react';
import {Address} from "@admin/api/profileInterfaces";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import getHornetLenderPropertiesList from "@hornet-api/properties/getHornetLenderPropertiesList";
import getHornetPropertiesList from "@hornet-api/properties/getHornetPropertiesList";
import {alertApiErrors} from "@common/errors";
import style from "@legacy/views/LoansAvailable/LoansAvailable.module.scss";
import {LoanStageEnum} from "@interfaces/GeneratedEnums";
import {AdvancedMarker, InfoWindow, Map, Pin, useMap} from "@vis.gl/react-google-maps";

interface IHornetPropertyAddress extends Address {
  // Loan Stage will be available only for Hornet Lender Loan's Property Address Type
  loanStage: keyof typeof LoanStageEnum | null;
}

type LegendInputItem = {
  label: string;
  markerSrc: string;
}

const MARKER_ICON_MAP = {
  RED: "/map-marker-red.png",
  YELLOW: "/map-marker-yellow.png",
  ORANGE: "/map-marker-orange.png",
  GREEN: "/map-marker-green.png"
}
const MARKER_ICON_COLORS = {
  RED: {MAIN: '#fb2104', OUTER: '#ba2103'},
  GREEN: {MAIN: '#5bd30c', OUTER: '#3d9702'},
  ORANGE: {MAIN: '#ff7934', OUTER: '#ba552c'},
  YELLOW: {MAIN: '#fad058', OUTER: '#a67d03'},
}

enum PROPERTY_TYPE_ENUM {
  PROPERTY_OWNED = "Properties Owned",
  LOAN_PIPELINE = "Loan Pipeline",
  ACTIVE_LOANS = "Active Loans",
  CLOSE_LOANS = "Closed Loans"
}

const PROPERTY_MARKER_MAP = {
  [PROPERTY_TYPE_ENUM.PROPERTY_OWNED]: MARKER_ICON_MAP.RED,
  [PROPERTY_TYPE_ENUM.LOAN_PIPELINE]: MARKER_ICON_MAP.YELLOW,
  [PROPERTY_TYPE_ENUM.ACTIVE_LOANS]: MARKER_ICON_MAP.GREEN,
  [PROPERTY_TYPE_ENUM.CLOSE_LOANS]: MARKER_ICON_MAP.ORANGE
};


const LegendItem = ({label, markerSrc}: LegendInputItem) => (
  <div className={style.legendItem}>
    <div className={style.legendItemIcon}>
      <img alt={label} height={'100%'} src={markerSrc}/>
    </div>
    <span>{label}</span>
  </div>
);

const LegendComponent = () => {
  return (
    <div className={style.legendBox}>
      <div className={style.legendHeading}>Legends</div>
      <div>
        {Object.entries(PROPERTY_MARKER_MAP).map(([key, value]) => (
          <LegendItem key={key} label={key} markerSrc={value}/>
        ))}
      </div>
    </div>
  );
};

const getMarkerColor = (loanStage: string | null) => {
  // Loan Stage will be available only for Hornet Lender Loan's Property Address Type
  if (!loanStage) {
    return MARKER_ICON_COLORS.RED;
  } else if (loanStage === 'ACTIVE') {
    return MARKER_ICON_COLORS.GREEN;
  } else if (loanStage === 'CLOSED') {
    return MARKER_ICON_COLORS.ORANGE;
  } else {
    return MARKER_ICON_COLORS.YELLOW;
  }
}

const getMarkerIcon = (loanStage: string | null) => {
  const {MAIN, OUTER} = getMarkerColor(loanStage);
  return <Pin background={MAIN} glyphColor={OUTER} borderColor={OUTER} scale={0.80}/>
}

const defaultLatLng = {lat: 39.33809297782211, lng: -95.53241097421876};
const defaultZoom: number = 12
const HornetPropertiesMap = () => {
  const map = useMap()
  const [addresses, setAddresses] = useState<IHornetPropertyAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<number>();

  useEffect(() => {
    if (!map) return
    const t = loadingTrigger();
    Promise.all([
      getHornetLenderPropertiesList(),
      getHornetPropertiesList()
    ])
      .then(([lenderPropertiesAddress, ownerPropertiesAddress]) => {
        // Loan Stage will be null for Hornet Owner Address Type
        return lenderPropertiesAddress.concat(ownerPropertiesAddress.map(address => ({...address, loanStage: null})))
      })
      .then(combinedProperties => {
        return Promise.all(combinedProperties.map(property => {
          return new Promise<IHornetPropertyAddress>((resolve) => {
            if (!property.longitude || !property.latitude) {
              const placesService = new window.google.maps.places.PlacesService(map);
              const query = `${property.address1} ${property.city} ${property.zip}`.replace(/\bnull\b/g, '').trim().replace(/\s+/g, ' ');
              placesService.findPlaceFromQuery({query: query, fields: ['geometry.location']}, (results, status) => {
                if (results && results.length && status !== 'ZERO_RESULTS' && results[0]?.geometry?.location) {
                  property.latitude = results[0].geometry.location.lat().toString();
                  property.longitude = results[0].geometry.location.lng().toString();
                }
                resolve(property);
              });
            } else {
              resolve(property)
            }
          })
        }));
      })
      .then(setAddresses)
      .catch(alertApiErrors)
      .finally(() => {
        loadingRelease(t);
      });
  }, [map]);

  useEffect(() => {
    if (!map) return
    const bounds = new window.google.maps.LatLngBounds();
    const validAddresses = addresses.filter(addr => addr.latitude !== null && addr.longitude !== null);
    validAddresses.forEach(coordinate => {
      bounds.extend(new window.google.maps.LatLng(Number(coordinate.latitude), Number(coordinate.longitude)));
    });
    map.fitBounds(bounds);
  }, [addresses, map]);

  const showPropertyInfoBox = selectedAddress && selectedAddress > -1 && addresses ? addresses[selectedAddress] : null;
  return (
    <div>
      <Map
        style={{height: '800px', width: '100%'}}
        mapId={'HornetPropMapId'}
        defaultZoom={defaultZoom}
        defaultCenter={defaultLatLng}
      >
        <LegendComponent/>
        {
          addresses?.map(({id, latitude, longitude, loanStage}, index) => {
            if (latitude && longitude && Number(latitude) && Number(longitude)) {
              return (
                <AdvancedMarker
                  key={`${id}-${index}`}
                  position={{
                    lat: Number(latitude),
                    lng: Number(longitude)
                  }}
                  onClick={() => setSelectedAddress(index)}
                >
                  {getMarkerIcon(loanStage)}
                </AdvancedMarker>
              )
            }
          })
        }
        {
          showPropertyInfoBox && (
            <InfoWindow
              position={{
                lat: Number(showPropertyInfoBox.latitude),
                lng: Number(showPropertyInfoBox.longitude)
              }}
              onCloseClick={() => setSelectedAddress(undefined)}
            >
              <div className={style.infoBox}>
                <div className={style.descriptionBox}>
                  <div>Address: {showPropertyInfoBox.address1}</div>
                  <div>City: {showPropertyInfoBox.city}</div>
                  <div>State: {showPropertyInfoBox.state}</div>
                </div>
                <div className={style.clearfix}/>
              </div>
            </InfoWindow>
          )
        }
      </Map>
    </div>
  )
}

export default HornetPropertiesMap;