import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {AdvancedMarker, Map, Pin, useMap} from "@vis.gl/react-google-maps";
import {IOption} from "@components/forms/intefaces";
import {MapLocation} from "@admin-ui/pages/PropertyPage/AddEditPropertyModal";

const defaultLatLng = {lat: 39.33809297782211, lng: -95.53241097421876};

const defaultContainerStyle = {
	width: '100%', height: '400px'
};
const ZOOM_MAP = {
	MIN: 8, MID: 11, MAX: 18
}

interface Property {
	mailingAddress: {
		address1: string,
		state: string | null,
		city: string,
		zip: string,
		latitude: number | null,
		longitude: number | null,
		counties: IOption [] | [],

	}

}

interface UserLoanComponentProp {
	property: Property;
	containerStyle?: React.CSSProperties;
	updateMapLocation?: ({longitude, latitude}: MapLocation) => void;
}

const UserLoanCollateralPropertyMap = ({property, containerStyle, updateMapLocation}: UserLoanComponentProp) => {
	const map: google.maps.Map | null = useMap();

	const [zoom, setZoom] = useState(18);
	const [latitudeLocal, setLatitudeLocal] = useState<number>();
	const [longitudeLocal, setLongitudeLocal] = useState<number>();
	const [label, setLabel] = useState("");
	const {address1, state, city, zip, latitude, longitude} = property.mailingAddress;

	const appendStringQuery = (text: string, stringQuery: string) => {
		return `${text} ${stringQuery} `;
	}
	const findPlace = (queryText: string, fallback?: () => void) => {
		const request = {
			query: queryText, fields: ['geometry.location']
		};
		if (!map) {
			return;
		}
		const placesService = new window.google.maps.places.PlacesService(map);
		placesService.findPlaceFromQuery(request, (results, status) => {
			if (results && status !== 'ZERO_RESULTS') {
				setLatitudeLocal(results[0]?.geometry?.location?.lat());
				setLongitudeLocal(results[0]?.geometry?.location?.lng());
				if (fallback) {
					setZoom(ZOOM_MAP.MAX);
				}
			} else if (fallback) {
				fallback()
			}
		});
	}

	const fallbackQuery = () => {
		const {state, city, zip, counties} = property.mailingAddress;
		const defaultCountry = 'United States';
		let fallbackStringQuery = '';
		if (counties && counties.length > 0) {
			fallbackStringQuery = appendStringQuery(counties[0].label, fallbackStringQuery);
			setZoom(ZOOM_MAP.MIN);
		}
		if (state && state !== 'null') {
			fallbackStringQuery = appendStringQuery(`${state},`, fallbackStringQuery);
			setZoom(ZOOM_MAP.MIN);
		}
		if (zip) {
			fallbackStringQuery = appendStringQuery(`${zip} `, fallbackStringQuery);
			setZoom(ZOOM_MAP.MID);
		}
		if (city) {
			fallbackStringQuery = appendStringQuery(`${city} `, fallbackStringQuery);
			setZoom(ZOOM_MAP.MID);
		}
		if (fallbackStringQuery.length > 0) {
			findPlace(` ${fallbackStringQuery} ${defaultCountry}`);
		}
	};

	useEffect(() => {
		if (!map) {
			return;
		}

		if (latitude && longitude && !isNaN(longitude) && !isNaN(latitude)) {
			setLatitudeLocal(Number(latitude));
			setLongitudeLocal(Number(longitude));
			setZoom(ZOOM_MAP.MAX);
		} else if (address1 || (state && state !== 'null') || city) {
			findPlace(`${address1} ${zip}`, fallbackQuery);
		}
		if (address1 || zip) {
			setLabel(`${address1 || ''} ${zip || ''}`);
		}
	}, [map, address1, state, city, zip, latitude, longitude]);


	const center = useMemo(() => {
		if (latitudeLocal && longitudeLocal) {
			return {
				lat: latitudeLocal, lng: longitudeLocal
			};
		}
	}, [latitudeLocal, longitudeLocal]);


	return (<Fragment>
		<div style={containerStyle || defaultContainerStyle}>
			<Map
				defaultZoom={zoom}
				center={center}
				defaultCenter={defaultLatLng}
				mapId={'LoanPropertyMapId'}
			>
				{!!(latitudeLocal && longitudeLocal) &&
					<AdvancedMarker
						key={label ? label : `markerKey`}
						position={{
							lat: latitudeLocal,
							lng: longitudeLocal
						}}
						title={label}
						draggable={!!updateMapLocation}
						onDragEnd={(event) => {
							updateMapLocation && event.latLng && updateMapLocation({
								latitude: event.latLng.lat(),
								longitude: event.latLng.lng()
							});
						}}
					>
						<Pin>
							<div style={{whiteSpace: 'nowrap'}}>
								<span
									style={{color: 'rgb(0, 0, 0)', fontSize: 14}}>
									{label}
								</span>
							</div>
						</Pin>
					</AdvancedMarker>
				}
			</Map>
		</div>
	</Fragment>);
};

export default UserLoanCollateralPropertyMap;
