import React, {useEffect, useState} from 'react';
import style from '@admin-ui/pages/TaskTemplates/TaskTemplates.module.scss';
import {TaskStatusEnum} from '@interfaces/GeneratedEnums';
import {hornetDark, hornetGray, hornetGreen, hornetOrange, hornetRed} from '@common/hornetColors';
import BootstrapSmartTable, {Column} from '@components/BootstrapSmartTable';
import {openTask} from '@common/tasks';
import {Link, useHistory, useParams} from 'react-router-dom';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {ITask} from "@interfaces/task";
import updateTask, {CreateTaskOptions} from "@hornet-api/task/updateTask";
import {refreshTaskPageState, triggerTaskRefresh} from "@components/NotificationSidebar/utils";
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import AddTaskToLoanModal, {addTaskModalState} from "@admin-ui/pages/LoanShowPage/components/AddTaskToLoanModal";
import {FaPencilAlt, FaTrash} from "react-icons/fa";
import {alertApiErrors} from "@common/errors";
import Button from "react-bootstrap/Button";
import deleteTaskAdmin from "@hornet-api/task/admin/deleteTaskAdmin";
import {getTaskByTaskId} from "@hornet-api/task/getTaskByTaskId";
import {selectedTaskDetailState, selectedTaskState} from "@components/NotificationSidebar/globalState";

const getStatus = (status: keyof typeof TaskStatusEnum) => {
  let color = hornetDark;

  switch (status) {
    case 'ACTIVE':
      color = hornetOrange;
      break;
    case 'COMPLETE':
      color = hornetGreen;
      break;
    case 'PENDING':
      color = hornetRed;
      break;
    case 'HIDDEN':
      color = hornetGray;
      break;
  }
  return (
    <div style={{color: color}}>
      {status in TaskStatusEnum ? TaskStatusEnum[status] : 'Unknown'}
    </div>
  );
};

type TaskUrlParams = {
  action?: string;
  id?: string;
}

const TasksPage = () => {
  const {action, id} = useParams() as TaskUrlParams;
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const reloadParam = refreshTaskPageState.useValue();
  const history = useHistory();

  const onClose = () => {
    setSelectedTask(null);
    history.push('/tasks');
  }

  const onEditTask = (task: ITask) => {
    setSelectedTask(task);
    if (task.contact) {
      addTaskModalState.set({taskId: task.id, contact: task.contact, onClose});
    } else if (task.entity) {
      addTaskModalState.set({taskId: task.id, entity: task.entity, onClose});
    }
  }

  useEffect(() => {
    if (id) {
      getTaskByTaskId(id).then((taskData) => onEditTask(taskData))
        .catch((e) => {
          console.error(e);
          alertApiErrors(e);
        })
    }
  }, [action, id]);

  const columns: Column<ITask>[] = [
    {
      id: 'title',
      title: 'Title',
      field: 'title',
      filter: true,
      render: (row) => (
        <a
          href={'#'}
          onClick={(e) => {
            e.preventDefault();
            openTask(row.id);
          }}
        >
          {row.title}
        </a>
      ),
    },
    {
      id: 'status',
      title: 'Status',
      field: 'taskStatus',
      filter: true,
      lookup: TaskStatusEnum,
      render: (row) => getStatus(row.taskStatus),
      onEdit: async (row) => {
        let submitData: CreateTaskOptions = {taskId: row.id, taskStatus: row.taskStatus};
        if (row.contact) {
          submitData = {...submitData, contactId: row.contact};
        }
        if (row.entity) {
          submitData = {...submitData, companyId: row.entity};
        }
        await updateTask(submitData);
        triggerTaskRefresh();
      },
    },
    {
      id: 'taskOwner',
      title: 'Owner',
      field: 'ownerName',
      filter: true,
      render: (row) => {
        let url = '';
        if (row.contact) {
          url = `/contact/show/${row.contact}`;
        }
        if (row.entity) {
          url = `/entity/show/${row.entity}`;
        }
        return <Link to={url}>{row.ownerName}</Link>;
      },
    },
    {
      id: 'loanId',
      title: 'Loan',
      field: 'loanId',
      filter: true,
      render: (row) => {
        if (!row.loanId) return <></>;
        return <Link to={`/loan/show/${row.loanId}`}>{row.loanId}</Link>;
      },
    },
    {
      id: 'action',
      title: 'Actions',
      field: 'actions',
      oneLine: true,
      actionBar: true,
      render: (row) => {
        return (
          <>
            <OverlayTrigger
              placement="bottom-end"
              overlay={<Tooltip id={`tt-edit-${row.id}`}>Edit</Tooltip>}
            >
              <Button
                size={'sm'}
                variant={'primary'}
                onClick={() => {
                  onEditTask(row);
                }}
              >
                <FaPencilAlt/>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom-end"
              overlay={<Tooltip id={`tt-edit-${row.id}`}>Delete</Tooltip>}
            >
              <Button
                size={'sm'}
                variant={'danger'}
                onClick={() => {
                  if (confirm("Are you sure you want to delete this task? This cannot be undone.")) {
                    deleteTaskAdmin(row.id)
                      .then(() => {
                        if (selectedTaskDetailState.get()?.id === row.id) {
                          selectedTaskDetailState.set(null);
                          selectedTaskState.set(null);
                        }
                      })
                      .then(triggerTaskRefresh)
                      .catch(alertApiErrors);
                  }
                }}
              >
                <FaTrash/>
              </Button>
            </OverlayTrigger>
          </>
        )
      }
    }
  ];

  return (
    <>
      <div className={style.container}>
        <section className="content-header">
          <div className="row">
            <div className="col">
              <div className="header-title">
                <h1>Tasks Page</h1>
                <small>Manage Tasks</small>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-lg-12 pinpin">
              <div className={'card'}>
                <div className="card-body">
                  <BootstrapSmartTable
                    name="loan-tasks"
                    columns={columns}
                    isRemote
                    remoteUrl={`${getAdminApiPath()}/task?_=${reloadParam}`}
                    defaultFilters={[['taskStatus', 'PENDING']]}
                    showFilter
                    defaultSortKey={['taskStatus', 'descending']}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {selectedTask && <AddTaskToLoanModal onComplete={() => {
        // Following function is going to refresh the global task state as well as this page
        // kept it there so that this page also gets refreshed on every websocket event for tasks
        triggerTaskRefresh()
      }}/>}
    </>
  );
};

export default TasksPage;
