import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FaCircleNotch} from "react-icons/fa";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {createGlobalState} from "react-global-hooks";
import {SubmitHandler, useForm} from "react-hook-form";
import {Checkbox, FileUploadControl, TextBox} from "@components/forms/react-hook-form-bootstrap";
import _ from "lodash";
import {DocumentUpload} from "@interfaces/Document";
import {DocumentTypeEnum} from "@interfaces/GeneratedEnums";

const {NDA, CREDIT, FREEFORM, PPM} = DocumentTypeEnum

interface DocumentUploadModalState {
  documentType: typeof NDA | typeof CREDIT | typeof FREEFORM | typeof PPM;
  onSubmit: (data: DocumentUpload) => Promise<void>;
  hideCheckbox?: boolean;
}

interface DocumentUploadFromData {
  documentType: keyof typeof DocumentTypeEnum
  //Only in freeform
  formName: string
  //only in case of ppm
  relationship: string
  memorandumNumber: string
  document?: File,
  isInternal: boolean
}

const defaultDocumentUploadFromData: DocumentUploadFromData = {
  documentType: "NDA",
  relationship: '',
  formName: '',
  memorandumNumber: '',
  isInternal: true
};

const documentUploadModalState = createGlobalState(null as null | DocumentUploadModalState);
export const openDocumentUploadModal = (modalState: DocumentUploadModalState) => {
  documentUploadModalState.set(modalState);
};

const titleFromDocType = (documentType: keyof typeof DocumentTypeEnum) => {
  switch (documentType) {
    case CREDIT:
      return "Credit";
    case NDA:
      return "NDA Upload"
    case FREEFORM:
      return "Free Form (Upload)"
    case PPM:
      return "PPM (Upload)"
  }
}

const DocumentUploadModal = () => {
  const [modalState, setModalState] = documentUploadModalState.use();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();
  const [documentUploadFromData, setDocumentUploadFromData] = useState<DocumentUploadFromData>()

  useEffect(() => {
    if (modalState) {
      setModalTitle(titleFromDocType(modalState.documentType));
      setDocumentUploadFromData({
        ...defaultDocumentUploadFromData,
        documentType: modalState.documentType
      })
    }

  }, [modalState]);

  useEffect(() => {
    if (documentUploadFromData) {
      reset(documentUploadFromData);
    } else {
      reset();
    }
  }, [documentUploadFromData]);

  const {
    control,
    handleSubmit,
    reset
  } = useForm<DocumentUploadFromData>();
  const getDefaultValue = useCallback(
    (name: string, defaultValue: any = '') => {
      return documentUploadFromData ? _.get(documentUploadFromData, name) : defaultValue;
    }, [documentUploadFromData]);

  const handleClose = () => {
    setModalState(null);
    setDocumentUploadFromData(defaultDocumentUploadFromData);
  };


  const onSubmit: SubmitHandler<DocumentUploadFromData> = async (data) => {
    if (!modalState || isSaving) return;
    setIsSaving(true);
    const requestData: DocumentUpload = {
      ...data,
      isCompany: false
    };
    modalState.onSubmit(requestData)
      .then(handleClose)
      .finally(() => setIsSaving(false));
  }

  return (<Modal
    show={!!modalState}
    onHide={handleClose}
    id={'documentUploadModal'}
    size={'lg'}
    onEntered={() => {
      setTimeout(() => {
        if (firstInputRef.current) firstInputRef.current.focus();
      }, 300);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{modalTitle}</Modal.Title>
    </Modal.Header>
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body className={'position-relative'}>
        {
          modalState?.documentType === FREEFORM &&
          <TextBox
            name={`formName`}
            label={'Enter custom form title'}
            type={'string'}
            control={control}
            rules={{
              required: true
            }}
            getDefaultFunction={getDefaultValue}
          />
        }

        {
          modalState?.documentType === PPM &&
          <>
            <TextBox
              name={`memorandumNumber`}
              label={'Memo#'}
              type={'number'}
              control={control}
              rules={{
                required: true
              }}
              getDefaultFunction={getDefaultValue}
            />
            <TextBox
              name={`relationship`}
              label={'Relationship Info'}
              type={'string'}
              control={control}
              rules={{
                required: true
              }}
              getDefaultFunction={getDefaultValue}
            />
          </>
        }
        <FileUploadControl
          name={'document'}
          label={'Document'}
          control={control}
          rules={{
            required: true
          }}
          getDefaultFunction={getDefaultValue}
        />
        {
          !modalState?.hideCheckbox && modalState?.documentType !== PPM &&
          <Checkbox
            name={'isInternal'}
            label={'Internal File'}
            control={control}
            getDefaultFunction={getDefaultValue}
          />
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={isSaving}
          type="submit"
        >{isSaving ? <FaCircleNotch className={'spin'}/> : 'Save'}</Button>
      </Modal.Footer>
    </Form>
  </Modal>)
}
export default DocumentUploadModal;