import React from 'react';
import {zoomState} from "./documentStates";
import {FaMinus, FaPlus} from 'react-icons/fa';
import style from './ZoomButtons.module.scss';
import classNames from "classnames";

const zoomOptions = [0.5, 0.75, 1, 1.25, 1.5, 2];

const ZoomButtons = () => {
  const [zoom, setZoom] = zoomState.use();

  const zoomIn = () => {
    const index = zoomOptions.indexOf(zoom);
    if (index === zoomOptions.length - 1) {
      return;
    }
    setZoom(zoomOptions[index + 1]);
  }

  const zoomOut = () => {
    const index = zoomOptions.indexOf(zoom);
    if (index === 0) {
      return;
    }
    setZoom(zoomOptions[index - 1]);
  }
  const canZoomIn = zoomOptions.indexOf(zoom) < zoomOptions.length - 1;
  const canZoomOut = zoomOptions.indexOf(zoom) > 0;
  return (
    <div className={style.btnBox}>
      <div
        className={canZoomIn ? style.btn : classNames(style.btn, style.disabled)}
        onClick={zoomIn}
      >
        <FaPlus/>
      </div>
      <div
        className={canZoomOut ? style.btn : classNames(style.btn, style.disabled)}
        onClick={zoomOut}
      >
        <FaMinus/>
      </div>
    </div>
  );
};

export default ZoomButtons;