import React, {useEffect, useMemo, useRef, useState} from 'react';
import {PDFDocument} from 'pdf-lib'; // https://www.npmjs.com/package/pdf-lib
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'; // https://www.npmjs.com/package/react-pdf
import {zoomState} from './documentStates';

import style from './DocumentRender.module.scss';
import ZoomButtons from "./ZoomButtons";
import fileDownloader from "@common/utils/fileDownloader";
import LoadingWithWrapper from "@components/Indicators/LoadingWithWrapper";
import {alertDownloadDocError} from "@common/errors";
import {windowWidthState} from "@state/globalState/windowSize";

type Props = {
  url: string,
  documentName: string,
}

type PageSize = {
  width: number,
  height: number
}

const DocumentRenderer = ({url, documentName}: Props) => {
  const [zoom, setZoom] = zoomState.use()
  const [numPages, setNumPages] = useState(null as null | number);
  const [displayFile, setDisplayFile] = useState(null as null | File);
  const docCanvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [pageSizes, setPageSizes] = useState<PageSize[]>([]);
  const windowWidth = windowWidthState.useValue();

  useEffect(() => {
    setIsLoading(true);
    if (windowWidth <= 768) {
      setZoom(0.5);
    } else if (windowWidth >= 769 && windowWidth <= 1024) {
      setZoom(0.8);
    }
    setPageSizes([]);
    setNumPages(0);
    setDisplayFile(null);

    // Improvement - we can use borrowerInvoice id instead of loanAliasId and servicingDate
    fileDownloader(
      url, documentName, true
    ).then(async (file) => {
      file = file as File
      const pdfInBytes = await file.arrayBuffer();

      const pdf = await PDFDocument.load(pdfInBytes);

      // Process all the page sizes
      const pages = pdf.getPages();
      let sizes: PageSize[] = [];
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        sizes.push({
          width: page.getWidth(),
          height: page.getHeight()
        })
      }
      setPageSizes(sizes);
      setNumPages(pages.length);
      const fileOut = new File([pdfInBytes], file.name);
      setDisplayFile(fileOut);
    }).catch(alertDownloadDocError).finally(() => setIsLoading(false));
  }, [url, documentName]);

  const pdfDocument = useMemo(() => {
    if (!displayFile) {
      return null;
    } else {
      return (
        <>
          {
            numPages ? Array.from({length: numPages}, (_, i) => i).map((index) => {
              const pageSize = pageSizes[index];
              return (<React.Fragment key={index}>
                <div
                  key={index}
                  className={style.pageBox}
                  style={{
                    position: 'relative',
                    width: pageSize.width * zoom,
                    height: pageSize.height * zoom
                  }}
                >
                  <Document
                    file={displayFile}
                    renderMode={'svg'}
                    className={'svg-doc'}
                  >
                    <Page
                      pageNumber={index + 1}
                      canvasRef={docCanvasRef}
                      className={'svg-page'}
                    />
                  </Document>
                </div>
                <div className={style.pageNumberBox}>
                  {index + 1} / {numPages}
                </div>
              </React.Fragment>)
            }) : null
          }
        </>
      )
    }
  }, [displayFile, numPages, pageSizes, zoom]);

  return (<>
    <div className='text-center'>
      <div className={style.buttonBoxWrapper}>
        <ZoomButtons/>
      </div>
      <div style={{
        display: 'inline-block',
        position: 'relative',
      }}>
        {pdfDocument}
      </div>
    </div>
    {isLoading && <LoadingWithWrapper absolute/>}
  </>)
}

export default DocumentRenderer;