import React, {useMemo} from 'react';
import {
  chatsState,
  notificationSidebarIsOpenState,
  selectedTopMenuItemState,
} from '@components/NotificationSidebar/globalState';
import isAdminState from "@state/globalState/isAdminState";
import Badge from './Badge';
import style from './style.module.scss';
import classNames from 'classnames';
import {getNumChatNotifications,} from '../utils';
import {BiMessage} from "react-icons/bi";

const AdminChatItem = () => {
  const chatObj = chatsState.useValue();
  const isAdmin = isAdminState.useValue();

  const numNotifications = useMemo(() => {
    let total = 0;
    if (chatObj) {
      // total += chatObj.announcement ? getNumChatNotifications(chatObj.announcement) : 0;
      if (chatObj.contactChat)
        total += getNumChatNotifications(chatObj.contactChat);
      if (chatObj.contactChats) {
        chatObj.contactChats.map((chat) => {
          total += getNumChatNotifications(chat);
        });
      }
      if (chatObj.loanChats) {
        chatObj.loanChats.map((chat) => {
          total += getNumChatNotifications(chat);
        });
      }
      // chatObj.entityChats.map((chat) => {
      //   total += getNumChatNotifications(chat);
      // });
    }

    return total;
  }, [chatObj, isAdmin]);

  return (
    <div className='top-icon'>
      <div
        className={
          isAdmin ? classNames(style.menuItem, style.isAdmin) : style.menuItem
        }
        onClick={() => {
          selectedTopMenuItemState.set("messages");
          notificationSidebarIsOpenState.set(true);
        }}
      >
        <BiMessage/>
        <div className={style.badgeHolder}>
          <Badge num={numNotifications}/>
        </div>
      </div>
    </div>
  );
};

export default AdminChatItem;
