import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import {PropertyServer} from "@interfaces/Property";
import {Link} from "react-router-dom";
import {IOptionWithEntityType} from "@admin-ui/pages/LoanPage/AddEditLoan/constant";
import {PropertyTypeEnum} from "@interfaces/GeneratedEnums";
import UserLoanCollateralPropertyMap from "@legacy/views/UserLoanView/UserLoanCollateralPropertyMap";

type Props = {
  property: PropertyServer
}
const ownedByText = (owner: IOptionWithEntityType) => {
  return owner.entityType === "CONTACT" ?
    <Link to={`/contact/show/${owner.value}`}>
      {owner.label}
    </Link>
    : <Link to={`/entity/show/${owner.value}`}>
      {owner.label}
    </Link>
}
export const PropertyInformationShow = ({property}: Props) => {
  const {latitude, longitude, address1, address2, address3, city, state, zip} = property.mailingAddress
  const hasLanLng = !!(latitude && longitude);
  return <Card>
    <Card.Header>Property Information</Card.Header>
    <Card.Body>
      <Row>
        <Col>
          <Row>
            <dt className="col-sm-6">Property Type:</dt>
            <dd className="col-sm-6">
              {
                property.propertyType && PropertyTypeEnum[property.propertyType]
              }
            </dd>
            <dt className="col-sm-6">Number of Units:</dt>
            <dd className="col-sm-6">{property.numberOfProperties}</dd>
            {/*Solely reflects as "Number of Units" on front-end so as differentiate this data from "Number Of Properties" label on the /loan/show/# > Collateral Table > "Number of Properties" column. They are not the same data. value for "property.numberOfProperties" is the value for "Number of Units".*/}
            <dt className="col-sm-6">lot size in acres:</dt>
            <dd className="col-sm-6">{property.lotSize}</dd>
            <dt className="col-sm-6">As-is Condition:</dt>
            <dd className="col-sm-6">{property.asIsCondition}</dd>
            <dt className="col-sm-6">Other features:</dt>
            <dd className="col-sm-6">{property.otherFeature}</dd>
            <dt className="col-sm-6">Final:</dt>
            <dd className="col-sm-6">{property.finalProperty}</dd>
            <dt className="col-sm-6">Initial:</dt>
            <dd className="col-sm-6">{property.initialProperty}</dd>
            <dt className="col-sm-6">Access Info:</dt>
            <dd className="col-sm-6">{property.accessInfo}</dd>
            <dt className="col-sm-6">Owned By:</dt>
            <dd className="col-sm-6">
              {
                property.owner && ownedByText(property.owner)
              }
            </dd>
            <dt className="col-sm-6">Address 1:</dt>
            <dd className="col-sm-6" id="address1">{address1}</dd>
            <dt className="col-sm-6">Address 2:</dt>
            <dd className="col-sm-6">{address2}</dd>
            <dt className="col-sm-6">Address 3:</dt>
            <dd className="col-sm-6">{address3}</dd>
            <dt className="col-sm-6">State:</dt>
            <dd className="col-sm-6">{state}</dd>
            <dt className="col-sm-6">City:</dt>
            <dd className="col-sm-6">{city}</dd>
            <dt className="col-sm-6">Zip:</dt>
            <dd className="col-sm-6">{zip}</dd>
            <dt className="col-sm-6">Latitude / Longitude:</dt>
            {
              hasLanLng ? <dd className="col-sm-6">
                  <span id="lat">{latitude}</span> / <span id="lng">{longitude}</span></dd>
                : <dd className="col-sm-6">Not Set</dd>
            }
            <dt className="col-sm-6">Counties:</dt>
            <dd className="col-sm-6">
              {
                property.counties.map((it) => <span key={it.id}
                                                    className='badge badge-pill badge-info mr-1'>{it.name}</span>)
              }
            </dd>
          </Row>
        </Col>
        <Col xs={7}>
          {
            property &&
              <UserLoanCollateralPropertyMap property={property}/>
          }
        </Col>
      </Row>
    </Card.Body>
  </Card>
}