/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useMemo, useState} from 'react';
import FontAwesomeIcon from '../../components/icons/FontAwesomeIcon';
import SearchBox from './SearchBox';
import {sidebarExpandedState} from './sidebarStore';
import {isLegacy} from '@common/isAdmin';
import {canSavePresetsState, rolesThatCanSavePresets, usersThatCanSavePresets} from '@hornet-api/smartTables';
import {Link} from "react-router-dom";
import activeUserSelector from "@state/globalState/user/activeUserSelector";
import isSuperAdminSelector from "@state/globalState/user/isSuperAdminSelector";
import {hornetRed} from "@common/hornetColors";
import ActiveRoleSelectorAdmin from "@admin/shared/AdminSidebar/ActiveRoleSelectorAdmin";
import AdminChatItem from "@components/NotificationSidebar/components/AdminChatItem";
import AdminTransfersItem from "@components/NotificationSidebar/components/AdminTransfersItem";
import isAdminState from "@state/globalState/isAdminState";
import AdminTasksItem from "@components/NotificationSidebar/components/AdminTasksItem";

import hornetCapitalWhiteLogo from '@assets/images/white_hornet_logo.svg'
import GlobalSearchState from "@admin/shared/AdminSidebar/GlobalSearchState";
import RecentSearch from "@admin/shared/AdminSidebar/RecentSearch";

const styles = {
	expander: {
		flexGrow: 1,
	},
	logo: {
		height: 25,
		margin: '13px auto',
		display: 'block'
	}
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = function (this: MediaQueryList, e: MediaQueryListEvent) {
			setMatches(e.matches)
		};
		mediaMatch.addEventListener("change", handler);
		return () => mediaMatch.removeEventListener("change", handler);
	});
	return matches;
};

const TopBar = () => {
	const [sidebarExpanded, setSidebarExpanded] = sidebarExpandedState.use();
	const activeUser = activeUserSelector();
	const isSuperAdmin = isSuperAdminSelector();
	const isAdmin = isAdminState.useValue();
	const isMobile = useMediaQuery('(max-width: 450px)');

	useEffect(() => {
		let roles: string[] = [];
		let username: string = '';
		if(isLegacy()) {
			// Legacy
			if (window?.role?.isAdmin) roles.push('ROLE_ADMIN');
			if (window?.role?.isSuperAdmin) roles.push('ROLE_SUPERADMIN');
			if (window?.role?.username) username = window.role.username;
		} else {
			if(activeUser) roles = [...activeUser.roles];
		}

		// Set if user can edit presets for smart tables on admin
		if (
			usersThatCanSavePresets.includes(username) || rolesThatCanSavePresets.some(x => roles.includes(x))
		) {
			canSavePresetsState.set(true);
		}
	}, [activeUser]);

	const userManagementControls = useMemo(() => {
		if (!isSuperAdmin) return null;
		return (
			<div className="top-icon">
				<FontAwesomeIcon icon="cog" />
				<div className="top-dropdown">
					{
						isLegacy() ?
							<a href="/advancedSettings/users" className="dd-item">
								<FontAwesomeIcon icon="bullhorn"/> User Management
							</a>
							:
							<Link to={'/advancedSettings/users'} className="dd-item">
								<FontAwesomeIcon icon="bullhorn"/> User Management
							</Link>
					}
				</div>
			</div>
		);
	}, [isSuperAdmin]);

	let reactUrl: string = (window as any).reactUrl ?? '';
	if(reactUrl.endsWith('/')) reactUrl = reactUrl.slice(0, -1);

	return (
		<div className={sidebarExpanded ? 'topbar' : 'topbar collapsed'}>
			{
				isLegacy() ?
					<div style={{
						background: hornetRed,
						position:"absolute",
						top:0,
						left: '50%',
						transform: 'translateX(-50%)',
						padding: '2px 20px',
						color: '#fff',
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            cursor: 'pointer',
            textAlign: 'center'
					}}
          onClick={() => {
            window.location.href =`${reactUrl}${window.location.pathname}${window.location.search}`;
          }}
          >
						LEGACY UI
            <div
              style={{
                textTransform: 'uppercase',
                fontSize: 10,
                marginTop: '-5px',
                fontWeight: 600
              }}
            >
              Click to switch to new ui
            </div>
					</div>
					:
					null
			}
			<div>
				<div className="expand-btn" onClick={() => {
					setSidebarExpanded(!sidebarExpanded);
				}}>
					<FontAwesomeIcon icon="chevron-left"/>
				</div>
			</div>
			<SearchBox/>
			<RecentSearch/>
			<div style={styles.expander}/>
			<ActiveRoleSelectorAdmin isMobile={isMobile}/>

			{
				isAdmin &&
				<>
					<AdminTransfersItem/>
					<AdminChatItem/>
					<AdminTasksItem/>
				</>
			}
			{userManagementControls}
			{
				isMobile ? null :
					<div className="top-icon">
						<img
							src={hornetCapitalWhiteLogo}
							style={styles.logo}
							alt={'Hornet Capital'}
						/>
						<div className="top-dropdown">
							<a href={`${reactUrl}/profile`}
								 className="dd-item">
								<FontAwesomeIcon icon="user"/> Profile
							</a>
							<a
								href={`${reactUrl}/logout`}
								className="dd-item"
								onClick={(e) => {
									if (isLegacy()) {
										// do nothing, will logout normally
										e.preventDefault()
										window.location.href = reactUrl + '/logout'
									} else {
										e.preventDefault();
										window.location.href = reactUrl + '/logout'
									}
								}}
							>
								<FontAwesomeIcon icon="sign-out"/> Logout
							</a>
						</div>

					</div>
			}
			<GlobalSearchState/>
		</div>
	);
};

export default TopBar;
