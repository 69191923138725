import React, {useEffect, useMemo, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {createGlobalState} from 'react-global-hooks';
import {FaCircleNotch} from 'react-icons/fa';

import {createUpdateTaskWithAttachment} from '@hornet-api/task/createTask';
import {LoanAdmin} from '@interfaces/LoanAdmin';
import {TaskStatusEnum, TaskTargetEnum} from '@interfaces/GeneratedEnums';
import {SubmitHandler, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {
  FileUploadControl,
  QuillEditorField,
  SearchableSelect,
  Select,
  TextBox
} from "@components/forms/react-hook-form-bootstrap";
import getTaskById from "@hornet-api/task/getTaskById";
import {CommonToolbarContainerItems} from "@common/quillToolbar";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import {companyListState, loanListState, userListState} from "@components/NotificationSidebar/globalState";
import {getLoanSelectOptions, getUserCompanySelectGroups, refreshTask} from "@components/NotificationSidebar/utils";
import {Attachment} from "@interfaces/task";
import ViewDownloadTaskFiles from "@admin-ui/pages/LoanShowPage/components/ViewDownloadTaskFiles";

interface ILoanTaskModalState {
  taskId?: number
  contact?: number
  entity?: number
  onClose?: () => void
}

export const addTaskModalState = createGlobalState<ILoanTaskModalState | null>(null);

type Props = {
  loan?: LoanAdmin;
  onComplete: () => void | Promise<void>;
};

export type CreateTaskFormData = {
  owner: string | { value: string, label: string | null },
  title: string,
  description: string,
  taskStatus?: keyof typeof TaskStatusEnum
  loanId?: string | { value: string, label: string },
  target: keyof typeof TaskTargetEnum;
  attachments: File[];
}

const AddTaskToLoanModal = ({loan, onComplete}: Props) => {
  const userList = userListState.useValue();
  const companyList = companyListState.useValue();
  const loanList = loanListState.useValue();
  const [modalState, setModalState] = addTaskModalState.use();
  const [isLoading, setIsLoading] = useState(false);
  const [taskAttachments, setTaskAttachments] = useState<Attachment[]>([]);
  const [templateAttachments, setTemplateAttachments] = useState<Attachment[]>([]);

  const groupOptions = useMemo(() => {
    return getUserCompanySelectGroups()
  }, [userList, companyList]);

  const loanOptions = useMemo(() => {
    return getLoanSelectOptions()
  }, [loanList])

  const editingId = modalState?.taskId;

  const {
    control,
    reset,
    handleSubmit
  } = useForm<CreateTaskFormData>();

  useEffect(() => {
    if (!modalState) return;
    if (editingId) {
      const t = loadingTrigger();
      getTaskById(editingId)
        .then(task => {
          setTaskAttachments(task.attachments);
          setTemplateAttachments(task.templateAttachments);
          const formData: CreateTaskFormData = {
            owner: ``,
            title: task.title,
            description: task.description,
            taskStatus: task.taskStatus,
            loanId: loanOptions?.find(({value}) => value === task.loanId),
            target: task.target,
            attachments: [],
          };
          if ("contact" in task) {
            formData.owner = `CONTACT-${task.contact}`
          } else {
            formData.owner = `ENTITY-${task.entity}`
          }
          if (!loan) {
            formData.owner = groupOptions["contact" in task ? 0 : 1]
                .options
                .find(({value}) => value === formData.owner)
              || '';
          }
          reset(formData);
        })
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
      return;
    }

    const ownerValue = loan ? `${loan.primaryBorrowerType}-${loan.primaryBorrowerId}` : ''
    const blankFormData: CreateTaskFormData = {
      owner: ownerValue,
      title: '',
      description: '',
      taskStatus: 'ACTIVE',
      loanId: loan?.aliasId,
      target: 'BORROWER',
      attachments: [],
    };
    reset(blankFormData);
  }, [modalState, editingId, loan, loanOptions, groupOptions])

  const handleClose = () => {
    setIsLoading(false);
    setModalState(null);
    modalState?.onClose?.();
  };

  const onSubmit: SubmitHandler<CreateTaskFormData> = (formData) => {
    setIsLoading(true);
    createUpdateTaskWithAttachment(formData, editingId)
      .then(onComplete)
      .then(handleClose)
      .then(refreshTask)
      .catch(alertApiErrors)
      .finally(() => setIsLoading(false))
  }

  return (
    <Modal show={!!modalState} onHide={handleClose} id={'add-task-modal'}>
      <Modal.Header closeButton>
        <Modal.Title>{editingId ? 'Edit Task' : 'Add Task'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          {
            loan ? (
              <Select
                name={'owner'}
                label={'Owner'}
                control={control}
                options={loan.taskOwners || []}
                emptyOption={'-- Please Select --'}
                rules={{required: true}}
              />
            ) : (
              <>
                <SearchableSelect
                  name={'owner'}
                  label={'Owner'}
                  control={control}
                  options={groupOptions}
                  rules={{required: true}}
                />
                <SearchableSelect
                  name={'loanId'}
                  label={'Loan'}
                  control={control}
                  options={loanOptions}
                />
              </>
            )
          }
          <TextBox
            name={'title'}
            label={'Title'}
            control={control}
            rules={{required: true}}
          />
          <Select
            name={'taskStatus'}
            label={'Status'}
            control={control}
            options={TaskStatusEnum}
            emptyOption={'-- Please Select --'}
            rules={{required: true}}
          />
          <Select
            name={'target'}
            label={'Is this a borrower or investor task?'}
            control={control}
            options={TaskTargetEnum}
            emptyOption={'-- Please Select --'}
            rules={{required: true}}
          />
          <QuillEditorField
            id={'quill-task-description'}
            name={'description'}
            label={'Description'}
            control={control}
            modules={{
              toolbar: {
                container: CommonToolbarContainerItems
              }
            }}
            rules={{required: true}}
          />

          <FileUploadControl
            name={'attachments'}
            label={'Attachments'}
            control={control}
            multiple={true}
          />

          {
            editingId && (<>
              {
                taskAttachments.length > 0 && (
                  <>
                  <span>
                    Task Attachments
                  </span>
                    <ViewDownloadTaskFiles
                      taskId={editingId}
                      files={taskAttachments}
                      setFiles={setTaskAttachments}
                      isDeletable={true}
                      isShow={!!modalState}
                    />
                  </>
                )
              }
              {
                templateAttachments.length > 0 && (
                  <>
                  <span>
                    Template Attachments
                  </span>
                    <ViewDownloadTaskFiles
                      taskId={editingId}
                      files={templateAttachments}
                      setFiles={setTemplateAttachments}
                      isDeletable={false}
                      isShow={!!modalState}
                    />
                  </>
                )
              }
            </>)
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type={'submit'} disabled={isLoading}>
            {isLoading ? <FaCircleNotch className="spin"/> : editingId ? 'Update Task' : 'Save Task'}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddTaskToLoanModal;
